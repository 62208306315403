import * as React from "react"
import { Link } from "gatsby"
import Layout from "../../components/Layout"
import FeaturePortfolioSummary from "../../components/FeaturePortfolioSummary"
import Seo from "../../components/Seo"
import { ArrowRightIcon } from '@heroicons/react/24/outline'

const AboutPage = () => {

  const anomalyFacts = [
    {
      title: "Capital raised",
      fact: "$0"
    },
    {
      title: "Years",
      fact: 2021-2004
    },
    {
      title: "Projects",
      fact: "28"
    },
    {
      title: "Location",
      fact: "AUS"
    },

  ];

  const cardData = [
    {
      title: "Capabilities",
      subTitle: "Learn about our strengths and approaches",
      target: "/capabilities"
    },
    {
      title: "Portfolio",
      subTitle: "See what we have done in the recent past",
      target: "/portfolio"
    },
    {
      title: "The Anomaly",
      subTitle: "The flagship painting by Shane Gehlert",
      target: "/about/painting"
    },
    {
      title: "Crypt of Tears",
      subTitle: "See our mark appear in an iconic Australia film",
      target: "/cot"
    }
  ];

  return (
    <Layout>
      <Seo 
        title="About"
        description="Discover who we are"
      />

      <div className="mx-4 lg:max-w-screen-lg lg:mx-auto"> 
        <h1 className="mb-4 text-5xl font-bold text-anomaly-red">About</h1>
        <p className="text-2xl font-medium text-gray-500 lg:max-w-2xl">Our purpose is to harness the magic of software, hardware &amp; connectivity to empower human endeavour.</p>
      </div>

      <ul className="grid max-w-screen-md grid-cols-2 gap-4 p-10 mx-4 my-10 shadow-2xl lg:mx-auto lg:grid-cols-4 rounded-3xl">
        {anomalyFacts.map(function(object, i){
          return (
            <li className="flex flex-col" key={i}>
              <p className="text-5xl font-bold text-gray-600">{object.fact}</p>
              <p className="mt-1 text-base text-gray-600">{object.title}</p>
            </li>
          );
        })}
      </ul>

      <div className="grid max-w-screen-md grid-cols-1 gap-8 p-6 mx-4 lg:mx-auto lg:grid-cols-2">
        <section>
          <h2 className="mb-4 text-2xl font-medium text-anomaly-red">Approach</h2>
          <p className="text-gray-500">
            Our approach to software development focuses on reusability, automation and scale. 
            Every solution is purpose-built without bias, with technology that best suits the problem. 
          </p>
          <p className="mt-4 text-gray-500">
            We weigh design and engineering equally.
          </p>
        </section>
        <section>
          <h2 className="mb-4 text-2xl font-medium text-anomaly-red">Ethos</h2>
          <p className="text-gray-500">
            We take responsibility for what we build and are not afraid to start again. 
            Each one of us challenges the status quo, pushing each other to do one better. 
          </p>
          <p className="mt-4 text-gray-500">
            We pride ourselves on our ethical standards and we actively invest into the future.
          </p>
        </section>
      </div>

      <h3 className="my-10 text-3xl font-semibold text-center text-anomaly-red">Trusted by</h3>
      <FeaturePortfolioSummary/>

      <div className="min-w-full mx-auto bg-white">
        <div className="max-w-screen-lg py-10 mx-4 my-10 y-6 lg:mx-auto">
          <h2 className="mb-4 text-3xl font-semibold text-gray-600">Regionally based, globally focused.</h2>
          <p className="text-xl font-medium text-gray-500">
            Lifestyle focused work environment to make us more productive.
          </p>
          <div className="flex flex-col justify-between mt-10 lg:flex-row">
            <section className="mb-8 lg:pb-0 lg:w-1/3 lg:mr-10">
              <h3 className="mb-4 text-2xl font-semibold text-anomaly-red">Calm</h3>
              <p className="text-gray-500">
                Our mission is to empower human endeavour - we believe in making things better.
              </p>
              <p className="mt-4 text-gray-500">
               We seek to be non disruptive because we believe in positively and calmly advancing the endeavour without negatively affecting the ecosystem. 
              </p>
              <p className="mt-4 text-gray-500">
                We don’t seek to displace systems that are working well already.
              </p>
            </section>
            <section className="mb-8 lg:pb-0 lg:w-1/3 lg:mr-10">
              <h3 className="mb-4 text-2xl font-semibold text-anomaly-red">Empathetic</h3>
              <p className="text-gray-500">
                The best outcomes come from being able to empathise with the client's problem.
              </p>
              <p className="mt-4 text-gray-500">
                We listen to your point of view, we seek to understand your strengths and limitations 
                and we build on this fundamental understanding to achieve a solution.
              </p>
            </section>
            <section className="mb-8 lb:pb-0 lg:w-1/3">
              <h3 className="mb-4 text-2xl font-semibold text-anomaly-red">Responsible</h3>
              <p className="text-gray-500">
                We understand the effect that software products have on users. 
                At Anomaly we take great responsibility in what we build by always the user at the centre of the product.
              </p>
              <p className="mt-4 text-gray-500">
                We also take responsibility in our footprint outside of what we create, and were one of the 
                first businesses in Australia to transition completely into electric transport.
              </p>
            </section>
          </div>
        </div>
      </div>
      <h3 className="mb-10 text-2xl font-semibold text-center text-anomaly-red">Discover more</h3>
      <ul className="grid grid-cols-1 gap-4 mx-14 md:grid-cols-2 lg:mx-auto lg:grid-cols-4 lg:flex-row lg:max-w-screen-lg text-anomaly-red">
        {cardData.map(function(link, i){
          return(
          <li key={i}>
            <Link to={link.target}>
            <section className="p-4 border-2 rounded-2xl border-anomaly-red">
             <div className="flex flex-row justify-between mb-24">
              <h4 className="font-semibold">{link.title}</h4>
              <ArrowRightIcon className="h-4 ml-2"/>
            </div>
            <p>{link.subTitle}</p>
            </section>
            </Link>
          </li>);
        })}
      </ul>
    </Layout>
 );
};
export default AboutPage
